<template>
	<main>
		<section
			class="constructor-page container-white"
			:class="{'mobile-container': mobileCheck()}"
		>
			<div class="container-base">
				<div class="constructor-page__breadcrumbs">
					<Breadcrumbs :linkArr="this.linkArr"/>
				</div>
				<div
					class="constructor-page__content"
					v-if="!endLunch"
				>
					<LunchConstructor
						@tocart="toCart"
						@lunchdone="lunchDone"
						:currentlunchname="name"
						:endLunch="endLunch"
						@setcurrentlunchname="setCurrentLunchName"
					/>
				</div>
				<div
					ref="endLunch"
					class="constructor-page__added"
					v-if="endLunch"
				>
					<div class="constructor-page__added-icon">
						<svg
							fill="transparent"
							width="60"
							height="60"
						>
							<use xlink:href="#sidebar-shop-icon"></use>
						</svg>
					</div>
					<h2>{{ getAddedInCartText }}</h2>
					<div class="constructor-page__added-btns">
						<router-link
							class="
								button button_green
								constructor-page__premiere-btn
							"
							to="/menu"
						>Продолжить покупки
						</router-link>
						<router-link
							v-if="!currentBasket?.collective"
							class="
								button button_red
								constructor-page__secondary-btn
							"
							to="/cart"
						>Оформить
						</router-link>
					</div>
				</div>
			</div>
		</section>
		<BottomNavigation/>
	</main>
</template>

<script>
import qs from "qs";
import Updater from "@/mixins/updater.js";
import {useYandexMetrika} from "@/composables";
import FoodTypeSelector from "../components/block/Menu/FoodTypeSelector.vue";
import BottomNavigation from "@/components/nav/BottomNavigation";
import {mobileCheck} from "@/helpers";
import {mapGetters} from "vuex";

export default {
	setup()
	{
		const {yandexMetrika} = useYandexMetrika();

		return {yandexMetrika};
	},
	components:
		{
			FoodTypeSelector,
			BottomNavigation,
			LunchConstructor: () => import("@/components/layout/LunchConstructor.vue"),
			Breadcrumbs: () => import("@/components/block/Breadcrumbs.old.vue"),
		},
	mixins: [Updater],
	data()
	{
		return {
			name: "",
			luchDone: false,
			endLunch: false,
			loaded: false,
			linkArr:
				[
					{
						name: "Меню",
						link: "/menu",
					},
					{
						name: "Конструктор",
						link: "/constructor",
					},
				],
		};
	},
	watch:
		{
			endLunch:
				{
					handler(newValue)
					{
						setTimeout(() =>
						{
							let headerHeight = document.querySelector("header").offsetHeight;
							if (newValue) window.scrollTo(0, this.$refs.endLunch.offsetTop - headerHeight);

						}, 200);
					},
					deep: true,
				},
		},
	computed:
		{
			...mapGetters({
				curRest: "order/currentRestaurant",
				currentBasket: "getCurrentBasket",
			}),
			getAddedInCartText()
			{
				const {businessMealType} = this.$store.state;
				const mealText = businessMealType === "lunch" ? "Ланч" : "Ужин";

				return `Бизнес-${mealText} “Создай свой ${mealText}” добавлен в корзину`;
			},
		},
	methods:
		{
			mobileCheck,
			/**
			 * устанавливает имя текущего ланча
			 */
			setCurrentLunchName(newValue)
			{
				if (newValue)
				{
					this.name = newValue;
				} else
				{
					this.name = this.$store.state.user.NAME || "";
				}
			},
			/**
			 * формирование объекта и отправка ланча на бек
			 */
			async sendLunch(lunches, names)
			{
				let data;

				if (lunches.length == 1)
				{
					data =
						{
							restId: this.$store.state.address.restarauntId,
							cartTypeId: this.currentBasket?.id,
							day: this.$store.state.currentDate,
							lanchId: this.$store.state.businessMeal.id,
							name: this.name,
							meals: [],
						};

					for (let mealIndex in lunches[0])
						data.meals.push(lunches[0][mealIndex].id);
				} else
				{
					this.name = this.$store.state.businessMeal.name;

					data =
						{
							restId: this.$store.state.address.restarauntId,
							cartTypeId: this.currentBasket?.id,
							day: this.$store.state.currentDate,
							lanchId: this.$store.state.businessMeal.id,
							name: this.name,
							lanches: [],
						};

					for (let i = 0; i < lunches.length; i++)
					{
						let tempLunch = [];
						let name;

						names[i]
							? name = names[i]
							: name = "Бизнес Ланч #" + Number(i + 1);

						for (let lun in lunches[i])
						{
							tempLunch.push(lunches[i][lun].id);
						}

						data.lanches.push({name: name, meals: tempLunch});
					}
				}

				let lunch = qs.stringify(data);

				let result = await this.$axios.post("/cart/addLanch/", lunch);

				if (result.data.success) return true;
			},

			/**
			 * завершает сборку ланча
			 */
			async lunchDone(lunches, names)
			{
				this.luchDone = true;
				const addLunch = await this.sendLunch(lunches, names);

				if (addLunch)
					this.endLunch = true;

			},
			/**
			 * добавляетланч в корзину
			 */
			toCart()
			{
				if (this.luchDone == true)
					this.endLunch = true;
			},
		},
	/**
	 * скроллит на мобилке до ланчей
	 */
	mounted()
	{
		this.yandexMetrika();
		let headerHeight = document.querySelector("header").offsetHeight;
		if (!this.$store.state.businessMeal || !this.$store.state.businessMealType)
			this.$router.push("/menu");

		if (window.innerWidth > 768)
			return false;

		setTimeout(() =>
		{
			document.querySelector("html").scrollTo(0, headerHeight);
		}, 1000);
	},
};
</script>

<style lang="scss">

.constructor-page__text
{
	font-size: 16px;
	line-height: 20px;
	letter-spacing: .1px;
	padding-left: 5px;
	color: $greyText;
	margin-bottom: 39px;
}

.constructor-page
{
	padding: 20px 0;
	padding-bottom: 93px;
}

.constructor-page__cart
{
	width: 462px;
	padding-left: 25px;
}

.constructor-page__products
{flex: 1;}

.constructor-page__search
{margin-bottom: 20px;}

.constructor-page__added
{
	max-width: 1207px;
	width: 100%;
	background: #fff;
	margin-left: auto;
	margin-right: auto;
	border: 1px solid #EDEDED;
	border-radius: 10px;
	padding: 60px 0;
	margin-bottom: 170px;
	margin-top: 70px;

	h2
	{
		text-align: center;
		font-size: 24px;
		line-height: 100%;
		margin-bottom: 54px;
	}
}

.constructor-page__added-btns
{
	display: flex;
	justify-content: center;
}

.constructor-page__premiere-btn
{
	width: 100%;
	max-width: 260px;
	margin-right: 25px;
	font-size: 16px;
	font-family: $mainFontBold;
}

.constructor-page__secondary-btn
{
	width: 100%;
	max-width: 220px;
	border: none;
	font-size: 16px;
	font-family: $mainFontBold;

}

.constructor-page__added-icon
{
	display: flex;
	justify-content: center;
	margin-bottom: 40px;
}

@media (max-width: 1200px)
{
	.constructor-page
	{
		.bottom-navigation
		{
			display: none;
		}
	}
}

@media (max-width: 767px)
{
	.constructor-page__added
	{
		padding-left: 10px;
		padding-right: 10px;
	}
	.constructor-page__added-btns
	{flex-wrap: wrap;}
	.constructor-page__premiere-btn,
	.constructor-page__secondary-btn
	{
		width: 100%;
		margin-right: 0;
		margin-bottom: 10px;
		min-width: 260px;
	}
	.constructor-page__text
	{display: none;}
	.constructor-page__breadcrumbs
	{display: none;}
}

@media (max-width: 567px)
{
	.constructor-page
	{padding-bottom: 245px;}
}
</style>
